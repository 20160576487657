import React from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import IndexPage from './IndexPage'; // Create this component for the source URL
import Contact from './Contact';
import About from './About';
import Services from './Services';
import TechnicalSupport from './TechnicalSupport';
import Terms from './Terms';
import Career from './Career';
import Privacy from './Privacy';
import Category from './Category';
import AllPhotos from './AllPhotos';
import EnglishWire from './EnglishWire'
import EnglishWireSearch from './EnglishWireSearch'
import VideoWire from './VideoWire'
import VideoWireSearch from './VideoWireSearch'
import PhotoWire from './PhotoWire'
import PhotoWireSearch from './PhotoWireSearch'
import AllPhotoWireSearch from './AllPhotoWireSearch'
import HindiWire from './HindiWire'
import HindiWireSearch from './HindiWireSearch'
import Login from './Login'
import Stories from './Stories'
import EnglishWireDetail from './EnglishWireDetail'
import BreakingNews from './BreakingNews'
import PhotoWireDetail from './PhotoWireDetail'
import HindiWireDetail from './HindiWireDetail'
import VideoWireDetail from './VideoWireDetail'
import Permision from './Permision'
import PhotoEvent from './PhotoEvent'
import AllPhotoWire from './AllPhotoWire'
import NewsWire from './NewsWire'
import NewsWireDetail from './NewsWireDetail'
import BusinessWire from './BusinessWire'
import BusinessWireDetail from './BusinessWireDetail'
import NewsReach from './NewsReach'
import NewsReachDetail from './NewsReachDetail'
import ElectionResult from './ElectionResult'
import CabinetMinisters from './CabinetMinisters'
import VideoWireIans from './VideoWireIans'
import JustIn from './JustIn'
import JDetail from './JDetail'
const App = () =>{
  return(
    <>
     
    <BrowserRouter>
    {/* <DarkTheme /> */}
      <Switch>
        <Route exact path="/hindi-wire/" component={HindiWire}/>

        <Route exact path="/">
        <Redirect to="/hindi-wire/all" />
      </Route>
        
        <Route exact path="/all-photos" component={AllPhotos}/>
        <Route exact path="/lok-sbabha-election-results-2024" component={ElectionResult} />
        <Route exact path="/stories" component={Stories}/>
        <Route exact path="/permision" component={Permision} />
        <Route exact path="/english-wire/all" component={EnglishWire}/>
        <Route exact path="/english-wire/:categoryId" component={EnglishWire}/>
        <Route exact path="/breaking-news" component={BreakingNews}/>
        <Route exact path="/breaking-news/:offset" component={BreakingNews}/>   
        <Route exact path="/pr-news-wire" component={NewsWire}/>
        <Route exact path="/pr-news-wire/:offset" component={NewsWire}/>   
        <Route exact path="/business-wire" component={BusinessWire}/>
        <Route exact path="/business-wire/:offset" component={BusinessWire}/>  
        <Route exact path="/vmpl/" component={NewsReach}/>
        <Route exact path="/vmpl/:offset" component={NewsReach}/>  
        <Route exact path="/hindi-wire/all" component={HindiWire}/>
        <Route exact path="/hindi-wire/:categoryId" component={HindiWire}/>
        <Route exact path="/cabinet-ministers" component={CabinetMinisters}/>
        <Route exact path="/video-wire/special_ians" component={VideoWireIans}/>
        <Route exact path="/video-wire/special_ians/:offset" component={VideoWireIans}/>
        <Route exact path="/video-wire/just_in" component={JustIn}/>
        <Route exact path="/video-wire/just_in/:offset" component={JustIn}/>
        <Route exact path="/video-wire/all" component={VideoWire}/>
        <Route exact path="/video-wire/:categoryId" component={VideoWire}/>
        <Route exact path="/video-wire/:categoryId/:offset" component={VideoWire}/>
        <Route exact path="/photo-wire/all" component={PhotoWire}/>
        <Route exact path="/photo-wire/:categoryId" component={PhotoWire}/>
        <Route exact path="/photo-wire/:categoryId/:offset" component={PhotoWire}/>   
        <Route exact path="/all-photo-wire/all" component={AllPhotoWire}/>
        <Route exact path="/all-photo-wire/:categoryId" component={AllPhotoWire}/>
        <Route exact path="/all-photo-wire/:categoryId/:offset" component={AllPhotoWire}/>              
        <Route exact path="/english-search/:search" component={EnglishWireSearch}/> 
        <Route exact path="/photo-search/:search" component={PhotoWireSearch}/>
        <Route exact path="/photo-search/:search/:offset" component={PhotoWireSearch}/>
        <Route exact path="/all-photo-search/:search" component={AllPhotoWireSearch}/>
        <Route exact path="/all-photo-search/:search/:offset" component={AllPhotoWireSearch}/>
        <Route exact path="/hindi-search/:search" component={HindiWireSearch}/>
        <Route exact path="/video-search/:search" component={VideoWireSearch}/>
        <Route exact path="/video-search/:search/:offset" component={VideoWireSearch}/>
        <Route exact path="/contact-us" component={Contact}/>
        <Route exact path="/services" component={Services}/>
        <Route exact path="/technical-support" component={TechnicalSupport}/>
        <Route exact path="/about-us" component={About}/>
        <Route exact path="/terms-of-use" component={Terms}/>
        <Route exact path="/career" component={Career}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/privacy-policy" component={Privacy}/>
        <Route exact path="/category/:categoryId" component={Category}/>
        <Route exact path="/category/:categoryId/:offset" component={Category}/>
        <Route exact path="/english-wire-detail/:newsId" component={EnglishWireDetail}/>
        <Route exact path="/photo-wire-detail/:photoId" component={PhotoWireDetail}/>
        <Route exact path="/hindi-wire-detail/:newsId" component={HindiWireDetail}/>
        <Route exact path="/video-wire-detail/:newsId" component={VideoWireDetail}/>
        <Route exact path="/just-in/:newsId" component={JDetail}/>

        <Route exact path="/pr-wire-detail/:newsId" component={NewsWireDetail}/>
        <Route exact path="/business-wire-detail/:newsId" component={BusinessWireDetail}/>
        <Route exact path="/vmpl/:newsId" component={NewsReachDetail}/>
        <Route exact path="/photo-event/:newsId" component={PhotoEvent}/>
        <Route path="/index.php" component={IndexPage} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
    </>
  )
}

export default App;
