import React, { useEffect } from 'react';
import axios from 'axios';

const VideoCat = (props) => {
  // const [poolData, setPoolData] = useState('');

  const SepcData2 = () => {
    axios
      .get('https://cms.iansnews.in/api/misc/?tags=olympics2024')
      .then((res) => {
        if (
          res.data.results &&
          res.data.results.length > 0 &&
          res.data.results[0].description
        ) {
          // setPoolData(res.data.results[0].description);
        }
      });
  };
  // const headingStyle = {
  //   color: 'grey',
  //   textAlign: 'center',

  // };
  useEffect(() => {
    SepcData2();
    // const sidebar = new StickySidebar('.sticky-sidebar', {
    //   topSpacing: 0, // Adjust as needed
    //   bottomSpacing: 0, // Adjust as needed
    // });
    //alert(props && props.currU)
    return () => {
    //   sidebar.destroy();
    };
  }, []);

  return (
    <>
      {props && props.data && props.data.length > 0 && (
        <>
            <ul className="nav flex-column sidebar">
              <div className="main-heading">
                <h2 className="heading">
                  {props.lang && props.lang === 'hindi'
                    ? 'कैटेगरी चयन करें'
                    : 'Choose Category'}
                </h2>
              </div>
              {props.data.map((tempCatArry, tc) => (
                <li className="nav-item" key={tc}>
                  <a
                    className={props && props.currU && props.currU === tempCatArry.slug ? "nav-link active" : "nav-link"}
                    aria-current="page"
                    href={`${props.slug}${tempCatArry.slug}`}
                  >
                    {props.lang && props.lang === 'hindi'
                      ? tempCatArry.hindi_name
                      : tempCatArry.name}
                  </a>
                </li>
              ))}
            </ul>
            
            {/* <div className="main-heading">
              <h2 className="heading" style={headingStyle}>Medal Tally</h2>
            </div>
            <div  dangerouslySetInnerHTML={{ __html:  poolData }}></div> */}
            <div className="app-icon-box">
              <div className="right_sec_new">
                <h2>
                  Get the latest news on the go. Download the <span>IANS</span>{' '}
                  App for free and stay tuned to all breaking stories, in-depth
                  news analyses and features.
                </h2>
                <div className="i-Phone">
                  <img
                    className="lazyloaded"
                    src="https://iansportalimages.s3.amazonaws.com/IANS-i-Phone.png"
                  />
                </div>
                <div className="ians-app-icon">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.news.ians"
                    target="_blank"
                  >
                    <img src="https://iansportalimages.s3.amazonaws.com/google-store.png" />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/ians/id1572306824"
                    target="_blank"
                  >
                    <img src="https://iansportalimages.s3.amazonaws.com/app-store.png" />
                  </a>
                </div>
              </div>
            </div>
          
        </>
      )}
    </>
  );
};

export default VideoCat;
